<template>
    <div class="container">
        <div class="search">
            <div class="search-item">
                <div class="key">领券方式：</div>
                <div class="value">
                    <el-select class="input" v-model="searchForm.couponWay" placeholder="全部">
                        <el-option v-for="item in couponWayList" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="search-item">
                <div class="key">是否用券：</div>
                <div class="value">
                    <el-select class="input" v-model="searchForm.useCoupon" placeholder="全部">
                        <el-option v-for="item in useCouponList" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="btn">
                <el-button @click="handleReset">重置</el-button>
                <el-button type="primary" @click="pageChange(1)">查询</el-button>
            </div>
        </div>
        <el-table :data="tableData" :header-cell-style="{height: '40px', background: 'rgba(246,248,250,1)'}">
            <el-table-column label="用户" prop="user_name">
                <template slot-scope="scope">
                    {{scope.row.user.name}}
                </template>
            </el-table-column>
            <el-table-column label="手机号码" prop="phone">
                <template slot-scope="scope">
                    {{scope.row.user.phone}}
                </template>
            </el-table-column>
            <el-table-column label="微信号" prop="user_wx_id"></el-table-column>
            <el-table-column label="领取方式"></el-table-column>
            <el-table-column label="领券时间">
                <template slot-scope="scope">
                    {{scope.row.create_time | timeFilter}}
                </template>
            </el-table-column>
            <el-table-column label="是否用券">
                <template slot-scope="scope">
                    {{scope.row.status | discountStatusFilter}}
                </template>
            </el-table-column>
            <el-table-column label="用券时间"></el-table-column>
        </el-table>
        <div class="page-container">
            <el-pagination layout="prev, pager, next" background @current-change="pageChange"
                :current-page="currentPage" :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import Core from '@/core';
export default {
    components: {},
    props: {
        id: {}
    },
    data() {
        return {
            tableData: [],
            total: 0,
            currentPage: 1,
            searchForm: {
                couponWay: undefined,
                useCoupon: undefined,
            },
            couponWayList: [{ value: 1, label: '全部' }],
            useCouponList: [{ value: 0, label: '初始化' }, { value: 10, label: '未使用' }, { value: 20, label: '已使用' }, { value: 30, label: '已过期' }]
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.getTableData();
    },
    methods: {
        getTableData() {
            Core.Api.Coupon.accountCoupon(this.id, this.currentPage, 10, this.searchForm.useCoupon).then((res) => {
                this.tableData = res.list;
                this.total = res.count;
            });
        },
        pageChange(page) {
            this.currentPage = page;
            this.getTableData();
        },
        handleReset() {
            this.searchForm.couponWay = undefined;
            this.searchForm.useCoupon = undefined;
            this.pageChange(1);
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    .search {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-bottom: 15px;
        margin-top: -15px;
        .search-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 20px;
            .key {
                font-size: 14px;
                color: #363d42;
            }
            .value {
                .input {
                    width: 268px;
                    height: 32px;
                }
            }
        }
        .btn {
            margin-left: 20px;
        }
    }
    .page-container {
        text-align: center;
        margin-top: 24px;
    }
}
</style>