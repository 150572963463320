<template>
    <div class="container">
        <el-button icon="el-icon-plus" class="btn" @click="showDialog">新增适用用户</el-button>
        <el-table :data="tableData" class="table"
            :header-cell-style="{height: '40px', background: 'rgba(246,248,250,1)'}">
            <el-table-column label="用户" prop="user_name">
                <template slot-scope="scope">
                    {{ scope.row.name || scope.row.username || scope.row.user_name}}
                </template>
            </el-table-column>
            <el-table-column label="手机号码" prop="phone"></el-table-column>
            <!-- <el-table-column label="微信号" prop="user_wx_id"></el-table-column>
            <el-table-column label="领取方式"></el-table-column>
            <el-table-column label="领券时间"></el-table-column>
            <el-table-column label="是否用券"></el-table-column>
            <el-table-column label="用券时间"></el-table-column> -->
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <!-- <el-button type="text" class="icon-btn" @click="handleUserDelete(scope.row)">
                        <el-tooltip placement="top" content="编辑">
                            <i class="iconfont icon-edit" />
                        </el-tooltip>
                    </el-button> -->
                    <el-button type="text" class="icon-btn" @click="handleUserDelete(scope.row)">
                        <el-tooltip placement="top" content="删除">
                            <i class="iconfont icon-delete" />
                        </el-tooltip>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="page-container">
            <el-pagination layout="prev, pager, next" background @current-change="pageChange"
                :current-page="currentPage" :total="total">
            </el-pagination>
        </div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="600px" :closed="handleDialogClose">
            <div class="dialog-content">
                <div class="form-item">
                    <div class="key">适用用户</div>
                    <el-select class="val" v-model="dialogForm.phone" filterable clearable placeholder="请选择用户(可通过手机号筛选)">
                        <el-option v-for="item of allUserList" :key="item.id" :label="item.phone" :value="item.phone">
                            <span style="float: left">{{ item.phone }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px;margin-left: 10px">{{ item.name }}</span>
                        </el-option>
                    </el-select>
                </div>
            </div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
                <el-button type="primary" @click="handleSave">保存</el-button>
                <el-button @click="handleDialogClose">取消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import Core from '@/core';
export default {
    components: {},
    props: {
        id: {}
    },
    data() {
        return {
            tableData: [],
            dialogVisible: false,
            dialogForm: {
                id: 0,
                phone: ''
            },
            title: '',
            currentPage: 1,
            total: 0,
            allUserList: [],
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.getUserList();
        this.getAllUserList();
    },
    methods: {
        getUserList() {
            Core.Api.Coupon.whiteList(this.id || 0, this.currentPage).then(
                (res) => {
                    this.tableData = res.list;
                    this.total = res.count;
                }
            );
        },
        getAllUserList() {
            Core.Api.User.allUserList().then(res => {
                console.log('getUserListAll', res);
                this.allUserList = res.list;
            });
        },
        pageChange(page) {
            this.currentPage = page;
            this.getUserList();
        },
        showDialog() {
            this.dialogVisible = true;
            this.dialogForm.phone = '';
            this.title = '新增适用用户';
        },
        handleDialogClose() {
            this.dialogVisible = false;
        },
        handleSave() {
            if (!this.dialogForm.phone) {
                return this.$message.error('请选择适用用户');
            }
            Core.Api.Coupon.whiteSave(this.id, this.dialogForm.phone).then(
                (res) => {
                    console.log(`res`, res);
                    this.$message.success('保存成功');
                    this.getUserList();
                    this.isShowUser = true;
                    this.dialogVisible = false;
                }
            );
        },
        handleUserEdit(item) {
            this.dialogForm.id = this.id;
            this.dialogForm.phone = item.phone;
            this.title = '修改白名单用户';
            this.dialogVisible = true;
        },
        handleUserDelete(item) {
            this.$confirm('确定执行删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.Coupon.whiteDelete(this.id, item.phone).then((res) => {
                    this.$message.success('删除成功!');
                    this.getUserList();
                });
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    .btn {
        color: #1D517E;
        border: 1px solid #1D517E;
        margin-bottom: 18px;
    }
    .page-container {
        text-align: center;
        margin-top: 24px;
    }
    .dialog-content {
        width: 100%;
        .form-item {
            @include flex(row, flex-start, center);
            .key {
                width: 100px;
                font-size: 14px;
                color: #8090a6;
            }
            .val {
                width: calc(100% - 100px);
            }
        }
    }
}
</style>