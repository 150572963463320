<template>
<div class="container">
    <div class="coupon-header border-container">
        <div class="title">
            <div class="text">
                <div class="text-1">{{detail.name}}</div>
                <!-- <div class="status"><span class="color">·</span>进行中</div> -->
            </div>

            <div class="btn">
                <el-button type="default" class="operation color" icon="el-icon-edit-outline"
                    @click="routerChange(1)">修改</el-button>
                <el-button type="default" class="operation color" icon="el-icon-delete" @click="handleDelete">删除
                </el-button>
                <el-button type="primary" class="operation" icon="el-icon-share" @click="showQRcodeDialog">分享
                </el-button>
            </div>
        </div>
        <div class="center">
            <div class="center-1">
                <span v-if="detail.price == 0 ">无门槛，</span>
                <span v-else>满{{detail.price / 100}}元，</span>
                <span v-if="detail.money">减{{detail.money / 100}}元</span>
                <span v-else>打{{detail.discount / 10}}折</span>
            </div>
            <div class="center-2">发行量：<span class="color">{{detail.send_count}}</span></div>
        </div>
        <div class="footer">
            <div class="footer-1">有效期：{{detail.begin_time | timeFilter(5)}} -- {{detail.end_time | timeFilter(5)}}
            </div>
        </div>
    </div>
    <el-dialog title="优惠券" :visible.sync="couponVisible" width="400px" :closed="handleQRCodeClose">
        <div class="coupon-qrcode">
            <img class="qrcode" :src="IMG_URL + '/public/create-coupon-qrcode'" alt="">
            <!-- <div class="qrcode" id="qrcode"></div> -->
            <!-- <el-input class="link" v-model="activityLink" placeholder=""></el-input> -->
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" icon="el-icon-download" @click="handleQRCodeDownload">保存图片</el-button>
            <!-- <el-button type="primary" icon="el-icon-document-copy" @click="handleCopyLink">复制链接</el-button> -->
        </span>
    </el-dialog>
    <div class="coupon-info border-container">
        <el-tabs class="my-tabs" v-model="activeTab">
            <el-tab-pane label="领/用券用户" name="led_goods">
                <use-user-list :id="coupon_id"></use-user-list>
            </el-tab-pane>
            <el-tab-pane label="用户群组" name="white_user" v-if="detail.user_range != 0">
                <white-user-list :id="coupon_id"></white-user-list>
            </el-tab-pane>
            <el-tab-pane label="用券商品" name="use_goods" v-if="detail.use_range != 0">
                <use-goods :id="coupon_id"></use-goods>
            </el-tab-pane>

        </el-tabs>
    </div>
</div>
</template>

<script>
import Core from '@/core';
import WhiteUserList from './components/WhiteUserList';
import UseGoods from './components/UseGoods';
import UseUserList from './components/UseUserList';

export default {
    components: { WhiteUserList, UseGoods, UseUserList },
    props: {},
    data() {
        return {
            activeTab: 'led_goods',
            coupon_id: '',
            detail: {},
            couponVisible: false,
            activityQrCodeImg: '',
            activityLink: '',
            IMG_URL: Core.Const.NET.URL_POINT_DEV
        };
    },
    watch: {},
    computed: {},
    created() {
        this.coupon_id = this.$route.query.id;
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        getDetail() {
            Core.Api.Coupon.detail(this.coupon_id).then((res) => {
                this.detail = res.detail;
            });
        },
        handleQRCodeClose() {
            this.couponVisible = false;
        },
        showQRcodeDialog() {
            this.couponVisible = true;
            this.activityLink = this.IMG_URL + `/public/create-coupon-qrcode`;
        },
        handleCopyLink() {
            this.$copyText(this.activityLink)
                .then((res) => {
                    this.$message.success('内容已复制到剪切板');
                })
                .catch((err) => {
                    this.$message.warning('复制失败');
                });
        },
        handleQRCodeDownload() {
            console.log('下载图片');
            let a = document.createElement('a');
            a.download = `${this.activityLink}`;
            a.href = this.activityLink;
            a.click();
        },
        handleDelete() {
            this.$confirm('确定执行删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.Coupon.delete(this.coupon_id).then(res => {
                    this.$message.success('删除成功!');
                    this.$router.go(-1);
                });
            });
        },
        routerChange(type) {
            switch (type) {
                case 1:
                    this.$router.push({
                        path: '/online-marketing/discount-edit',
                        query: {
                            id: this.coupon_id
                        }
                    });
                    break;
            }
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    .coupon-header {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 20px;
        margin: 20px;

        .title {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 14px;
            .text {
                display: flex;
                flex-direction: row;
                align-items: center;
                .text-1 {
                    font-size: 16px;
                    color: #323538;
                    font-weight: 500;
                    margin-right: 16px;
                }
                .status {
                    font-size: 14px;
                    color: #363d42;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .color {
                        color: #13c176;
                        font-size: 36px;
                    }
                }
            }

            .btn {
                .operation {
                    width: 80px;
                    height: 30px;
                    font-size: 14px;

                    &.color {
                        color: #1D517E;
                        border: 1px solid #1D517E;
                    }
                }
            }
        }
        .center {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-bottom: 25px;
            font-size: 14px;

            .center-1 {
                color: #363d42;
                margin-right: 69px;
            }
            .center-2 {
                color: #999999;

                .color {
                    color: #363d42;
                }
            }
        }
        .footer {
            .footer-1 {
                font-size: 14px;
                color: #999999;
            }
        }
    }
    .coupon-qrcode {
        display: flex;
        flex-direction: column;
        align-items: center;
        .qrcode {
            width: 320px;
            height: 320px;
            // padding: 3px;
            // border: 1px solid #e7ecf1;
            // background: #f8fafc;
            img {
                height: 100%;
            }
        }
        .link {
            width: 300px;
            margin-top: 16px;
        }
    }
    .coupon-info {
        margin: 20px;
        background-color: #fff;
    }
}
</style>
<style lang="scss">
.course-info .my-tabs {
    > .el-tabs__header.is-top {
        .el-tabs__active-bar {
            width: 64px;
            height: 4px;
            border-radius: 2px;
        }
        #tab-special_column,
        #tab-student_list,
        #tab-data_analysis {
            height: 60px;
            line-height: 60px;
            font-size: 16px;
            font-weight: 500;
        }
    }
    > .el-tabs__content {
        padding: 16px 0 16px 20px;
    }
}
</style>
