<template>
    <div class="container">
        <el-table :data="tableData" :header-cell-style="{height: '40px', background: 'rgba(246,248,250,1)'}">
            <el-table-column label="商品名称">
                <template slot-scope="scope">
                    {{scope.row.course.name}}
                </template>
            </el-table-column>
            <el-table-column label="商品类型">
                <template slot-scope="scope">
                    {{scope.row.course.media_type | courseTypeFilter}}
                </template>
            </el-table-column>
            <el-table-column label="创建时间">
                <template slot-scope="scope">
                    {{scope.row.course.create_time | timeFilter}}
                </template>
            </el-table-column>
        </el-table>
        <div class="page-container">
            <el-pagination layout="prev, pager, next" background @current-change="pageChange"
                :current-page="currentPage" :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import Core from '@/core';
export default {
    components: {},
    props: {
        id: {}
    },
    data() {
        return {
            tableData: [],
            total: 0,
            currentPage: 1
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        this.getTableData();
    },
    methods: {
        getTableData() {
            Core.Api.Coupon.useGoods(this.id, this.currentPage).then((res) => {
                console.log('res:', res)
                this.tableData = res.list;
                this.total = res.count;
            });
        },
        pageChange(page) {
            this.currentPage = page;
            this.getTableData();
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    .page-container {
        text-align: center;
        margin-top: 24px;
    }
}
</style>